import * as R from "ramda";
import Img from "gatsby-image";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Item = props => {
  const {
    theme,
    post: {
      excerpt,
      fields: { slug, prefix },
      frontmatter: { title, category, author, cover }
    }
  } = props;

  const coverImage = R.path(["children", 0, "fluid"])(cover);

  return (
    <React.Fragment>
      <Link to={`/news${slug}`} key={slug} className="link">
        <div className="post">
          <div className="gatsby-image-outer-wrapper">
            {coverImage ? <Img fluid={coverImage} /> : <div className="cover-placeholder" />}
          </div>
          <div className="details">
            <h1>{title}</h1>
            <p>{excerpt}</p>
            <div className="line tablet" />
            <p>{author}</p>
            <p className="date">{prefix}</p>
            <div className="line mobile" />
          </div>
        </div>
      </Link>

      {/* --- STYLES --- */}
      <style jsx>{`
        :global(.link) {
          width: 100%;
          color: ${theme.text.color.primary};
        }

        .post {
          margin: 10px 0 20px 0;
          position: relative;
          transition: all ${theme.time.duration.default};
          background: transparent;
          display: flex;
          flex-wrap: wrap;

          .details {
            flex-grow: 1;
            padding: ${theme.space.default} 0;
          }

          :global(.gatsby-image-wrapper) {
            transition: all ${theme.time.duration.default};
            background: ${theme.line.color};
          }

          :global(.gatsby-image-outer-wrapper) {
            width: 100%;
            min-width: 100%;
            overflow: hidden;
          }

          &:hover {
            :global(.gatsby-image-wrapper) {
              transform: scale(1.1);
            }
            h1 {
              color: ${theme.blog.h1.hoverColor};
            }
          }
        }

        h1 {
          line-height: ${theme.blog.h1.lineHeight};
          font-size: ${theme.blog.h1.size};
          font-family: ${theme.font.family.thaiText};
          color: ${theme.color.brand.primaryBackground};
          text-remove-gap: both;
          margin-bottom: 0.75em;
          transition: all ${theme.time.duration.default};
        }

        .line {
          width: 100%;
          border-bottom: 1px solid ${theme.color.neutral.gray.e};
          margin: 1.5rem 0 1rem 0;
        }

        .line.tablet {
          display: none;
        }

        p {
          line-height: 1.5;
          font-family: ${theme.font.family.thaiText};
          color: ${theme.color.neutral.gray.k};
          font-weight: 500;
          text-remove-gap: both;
          margin: 1rem 0;
        }

        p.date {
          margin-top: -0.5rem;
          font-size: small;
        }

        .cover-placeholder {
          background-color: ${theme.color.neutral.gray.c};
          width: 100%;
          height: 100%;
        }

        @from-width tablet {
          .post {
            margin: 10px 0 40px 0;
            flex-wrap: nowrap;

            :global(.gatsby-image-outer-wrapper) {
              width: 50%;
              min-width: 50%;
            }

            :global(.gatsby-image-wrapper) {
              height: 100%;
            }

            .details {
              padding: ${`0 0 0 ${theme.space.default}`};
            }
          }

          h1 {
            font-size: ${`calc(${theme.blog.h1.size} * 1.2)`};
          }

          .line.tablet {
            display: block;
          }

          .line.mobile {
            display: none;
          }
        }

        @from-width desktop {
          .post {
            margin: 10px 0 50px 0;

            :global(.gatsby-image-outer-wrapper) {
              width: 60%;
              min-width: 60%;
            }
          }

          h1 {
            font-size: ${`calc(${theme.blog.h1.size} * 1.5)`};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Item.propTypes = {
  post: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Item;
