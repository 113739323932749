import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { ThemeContext } from "../layouts";
import Item from "../components/Blog/Item";
import Title from "../components/Title/Title";

const Pagination = ({ currentPage, numPages, theme }) => {
  const nextHref = `/news/${currentPage + 1}`;
  const prevHref = `/news/${currentPage - 1 === 1 ? "" : currentPage - 1}`;
  return (
    <React.Fragment>
      <div className="pagination">
        <div className="link">
          {currentPage !== 1 && (
            <Link to={prevHref}>
              <FaArrowLeft /> Previous Page
            </Link>
          )}
        </div>

        <div className="center">
          {currentPage} <span className="num-pages">/ {numPages}</span>
        </div>

        <div className="link">
          {currentPage < numPages && (
            <Link to={nextHref}>
              Next Page <FaArrowRight />
            </Link>
          )}
        </div>
      </div>

      <style jsx>{`
        .pagination {
          display: flex;
          margin: 2rem 0;
          font-family: ${theme.font.family.thaiText};

          .center {
            flex-grow: 1;
            text-align: center;
            color: ${theme.color.neutral.gray.k};
            .num-pages {
              opacity: 0.5;
            }
          }

          .link {
            width: 150px;
            & :global(a) {
              font-weight: 600;
              color: ${theme.color.brand.primaryBackground};
            }
            & > :global(a > svg) {
              font-size: 12px;
            }
            &:last-child {
              text-align: right;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const NewsPage = props => {
  const {
    data: {
      news: { edges: news = [] }
    },
    pageContext: { currentPage, numPages }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <div className="container">
            <Title theme={theme}>News</Title>
            {numPages > 1 && (
              <Pagination currentPage={currentPage} numPages={numPages} theme={theme} />
            )}
            {news.map(post => {
              const {
                node,
                node: {
                  fields: { slug }
                }
              } = post;
              return <Item key={slug} post={node} theme={theme} />;
            })}
            {numPages > 1 && (
              <Pagination currentPage={currentPage} numPages={numPages} theme={theme} />
            )}
          </div>
        )}
      </ThemeContext.Consumer>

      <style jsx>{`
        .container {
          margin-top: 100px;
          padding: 40px;
        }

        .pagination {
          display: flex;
          margin: 2rem 0 0 0;
          .center {
            flex-grow: 1;
            text-align: center;
          }
          .link {
            width: 150px;
            & > :global(a > svg) {
              font-size: 12px;
            }
            &:last-child {
              text-align: right;
            }
          }
        }

        @below desktop {
          .container {
            margin-top: 85px;
            padding: 20px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

NewsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default NewsPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    news: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
